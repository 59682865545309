export default class Nav {
    constructor() {
        this.addEventListener();
    }

    addEventListener() {
        const burgerIcon = document.querySelector(".navigation__burger");
        const menu = document.getElementById("nav");
        const logo = document.querySelector(".navigation__logo");
        const nav = document.querySelector(".navigation");

        // console.log(nav);

        burgerIcon.addEventListener("click", (e) => {
            burgerIcon.classList.toggle("open");
            menu.classList.toggle("hide");
            logo.classList.toggle("hide");
            document.querySelector("body").classList.remove("is-sticky-header");
            document.querySelector("body").classList.toggle("stop-scrolling");


        });

    }
}
