'use strict';

export default class FlashMessage {
    constructor() {
        this.close = document.querySelectorAll('.js-flash-message_close');

        this.listeners();
    }

    listeners() {
        this.close.forEach(el => {
            el.addEventListener('click', this.closeFlashMessage, false);
        });
    }

    closeFlashMessage(el) {
        el.preventDefault();
        el.stopPropagation();

        let hideMessages = true;

        el.currentTarget.blur();
        el.currentTarget.closest('.js-flash-message:not(.js-alert-template)').classList.add('is-dismissed');

        document.querySelectorAll('.js-flash-messages .js-flash-message:not(.js-alert-template)').forEach(el => {
            if(!el.classList.contains('is-dismissed'))
                hideMessages = false;
        });

        if(hideMessages) {
            setTimeout(function() {
                document.querySelector('.js-flash-messages').classList.add('is-all-dismissed');
            }, 600);
        }
    }
}
