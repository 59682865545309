/*
 * Essential Imports
 */

// import 'babel-polyfill';
import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';


/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload')
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}


/*
 * AppendAround
 */

import appendAround from './functions/append-around';

window.addEventListener('load', function() {
    appendAround();
});


/*
 * Cookies
 */

import Cookies from 'js-cookie';

let cookieNotice = document.getElementById('cookie-notice');
if(cookieNotice != 'undefined' && cookieNotice != null && Cookies.get('cookie-notice') !== 'true') {
    document.querySelectorAll('.js-cookie_close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('cookie-notice', 'true', { expires : 7 });
            cookieNotice.classList.add('hide');
        }, false);
    });
};


/*
 * FlashMessage
 */

import FlashMessage from './functions/flash-message';
const flash_message = new FlashMessage();

/*
 * Forms
 */

// FormValidation
import './functions/form-validation';
document.querySelectorAll('.form.js-validate').forEach(form => {

    let recaptcha = false;

    if (form.querySelector('.g-recaptcha')) {
        recaptcha = document.querySelector("meta[name='recaptcha_site_key']").getAttribute('content');
    }

    new Validate(form, {
        recaptchaSiteKey: recaptcha,
        fieldFeedbackSelector: '.js-field-feedback span',
    });

    // Focus invalid field behaviour
    form.addEventListener('submit', function () {
        let invalidField = form.querySelector('.js-field.is-invalid');
        if (invalidField) {
            scrollToElem(invalidField);
        }
    });
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach(field => {
    field.addEventListener('keyup', event => {
        field.setAttribute('value', field.value);
    });
});


/*
 * GoTo Elem
 */

if(typeof(document.querySelector('.js-goto_elem')) != 'undefined' && document.querySelector('.js-goto_elem') != null) {
    document.querySelectorAll('.js-goto_elem').forEach(el => {
        if(typeof(document.getElementById(el.dataset.elem)) != 'undefined' && document.getElementById(el.dataset.elem) != null) {
            el.addEventListener('click', event => {
                scrollToElem(document.getElementById(el.dataset.elem));
            });
        }
    });
}

/*
 * Modal Window
 */

import ModalWindow from './functions/modal-window';
const modal_window = new ModalWindow();


/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
const sticky_header = new StickyHeader();


/*
 * Swiper
 */

/*
import './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach(swiper => {
    let swiperID = swiper.id;
    let params = swiper.dataset.swiper;
    let mySwiper = new Swiper(swiper, JSON.parse(params));

    // Swiper Height
    document.addEventListener('lazyloaded', function (e) {
        if(swiper.dataset.autoheight)
            mySwiper.updateAutoHeight();
    });
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach(goto => {
    goto.addEventListener('click', event => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});
*/


/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);


/*
 * Video
 */

import Video from './functions/video';
const video = new Video();


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUPPORT FUNCTIONS ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * scrollToElem
 */

const scrollToElem = (elem, offset = 0) => {
    let headerHeight = document.querySelector('.header').offsetHeight;

    if(typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null) {
        headerHeight += document.querySelector('.js-sticky').offsetHeight;
    }

    const y = elem.getBoundingClientRect().top + window.pageYOffset - headerHeight - offset;

    setTimeout(function () {
        window.scrollTo({
            top : y,
            behavior : 'smooth'
        });
    }, 1);
};


/*
 * elemSiblings
 */

const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANALYTICS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * Cookie Controller
 */

/*
import './functions/cookie-controller';
const cookieController = new CookieController({
    layout: 'banner',
    requiredCookies: ['PHPSESSID'],
    optionalGroups: [
        {
            name: 'google_analytics',
            label: 'Google Analytics',
            description: 'These cookies allow us to <strong>anonymously</strong> collect data about how you browse and use our website to improve it for you and others over time.',
            cookies: ['_ga', '_ga_QDEZCD4BZB'],
            defaultState: 'off',
            recommendedState: 'on',
            onAllow() {
                dataLayer.push({
                    'event': 'ccGoogleAnalytics',
                    'allow': 'allow',
                    'eventCallback' : function () {
                        gritEvent.init();
                    },
                });
            },
            onDisallow() {
                dataLayer.push({
                    'event': 'ccGoogleAnalytics',
                    'allow': '',
                });
            },
        },
        {
            name: 'facebook_pixel',
            label: 'Facebook Pixel',
            description: 'These cookies allow us to <strong>anonymously</strong> collect data about how you browse and use our website to improve it for you and others over time.',
            cookies: ['fr', '_fbp'],
            defaultState: 'off',
            recommendedState: 'on',
            onAllow() {
                dataLayer.push({
                    'event': 'ccFacebookPixel',
                    'allow': 'allow',
                });
            },
            onDisallow() {
                dataLayer.push({
                    'event': 'ccFacebookPixel',
                    'allow': '',
                });
            },
        },
    ],
});




 */

//import './functions/ga-events';

/* ------------------------------------------------------------------------------------------------------------------------ */
/* Quick Links Hover ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */


{
    let quickLinkCol = document.querySelectorAll(`.quick-links__quick-link`);
    let activeInd = undefined;
    for(let i = 0; i < quickLinkCol.length; i++) {
        quickLinkCol[i].addEventListener('mouseenter', (e) => {
            let currentIndVal = e.currentTarget.getAttribute('col-index');
            if(activeInd != currentIndVal) {
                activeInd = currentIndVal;
                // Add darken class to all
                for(let ci = 0; ci < quickLinkCol.length; ci++) {
                    if(ci != currentIndVal) quickLinkCol[ci].classList.add('darken');
                }
            }
        });

        quickLinkCol[i].addEventListener('mouseleave', (e) => {
            activeInd = undefined;
            for(let ci = 0; ci < quickLinkCol.length; ci++) {
                quickLinkCol[ci].classList.remove('darken');
            }
        });
    }
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TRIGGER ANIMATIONS ------------------------------------------------------------------------------------------------------*/
/* ------------------------------------------------------------------------------------------------------------------------ */

const initiateIntersectionObserver = () => {
    let animationElements = document.querySelectorAll('[trigger-animation]');
    const config = {
        threshold: [0, 0.25, 0.75, 1]
    };
    let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                // In view
                entry.target.classList.add('animate');
            } 
            // else {
            //     // Out of view
            //     entry.target.classList.remove('animate');
            // }
        });
    }, config);
    animationElements.forEach((ele) => {
        observer.observe(ele);
    });
}
initiateIntersectionObserver();

/* ------------------------------------------------------------------------------------------------------------------------ */
/* Parallax --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

import Rellax from './functions/rellax.min.js';
var rellax = new Rellax('.rellax', {
    center: true,
});

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RECENT EVENTS ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

import Swiper from './vendor/swiper';

new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
    navigation: {
        nextEl: '.swiper-button-next'
    },
    loop: true,
    autoplay: true
});

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO SLIDER ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

new Swiper('.hero-slider__swiper-container', {
    slidesPerView: 1,
    pagination: {
        el: '.swiper-pagination'
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
});
new Swiper('.hero-banner-slider__swiper-container', {
    slidesPerView: 1,
    autoplay: true,
    effect: 'fade',
});

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NAVIGATION -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */


import Nav from './functions/nav';
const nav = new Nav();

